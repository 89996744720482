import React from 'react'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import './videoIntro.css'
import {Langage} from "../../language";
import {appLanguage, titleNormalized} from "../../select";
import {Box} from "@mui/material";
import { handleVideo } from '../../utils/utils';

let showDisableLecture = false;
const getText = (key) => {
    return Langage[key][appLanguage];
}

const muteTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'true');
}

const activateTextToSpeech = () => {
    localStorage.setItem('MUTE_TTS', 'false');
}

const VideoIntro = (props) => {

    return (
        <>
            <Button onClick={()=> {handleVideo(`/assets/videos/intro-${titleNormalized}-${appLanguage}.mp4`, null, 10200); props.actionProvider.next(getText('start_diag'), 0) }}
                    variant="contained" className={'btn-black'} endIcon={<DoneIcon />}>
                {getText('i_accept')}
            </Button>
            
            <div id="container_vid">
                <video style={{margin:"auto"}} playsInline id="vid">
                    <source type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* <div style={{margin:"auto"}}>
                    <div id="vid-text" className="react-chatbot-kit-chat-bot-message">
                        <span id="vid_text_span"></span>
                    </div>
                </div> */}
                
            </div>

            {/* <Box>
                {
                    showDisableLecture && <Button variant="contained" className={'btn-black'} onClick={()=> { muteTextToSpeech() }}>
                        {getText('disable_lecture')}
                    </Button>
                }
            </Box> */}
        </>
    )
}

export default VideoIntro
