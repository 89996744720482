import React from 'react';
import { registerProfile, getToken, findProfileByEmail, relatedTo, moderation, createfollowingChat, updatefollowingChat } from '../api/service';


const MessageParser = ({ children, actions }) => {

  let historyMessage = children.props.state.messages
  const setState = children.props.setState
  const states = children.props.state
  const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

  const findLastMsgBot = () => {
    console.log(historyMessage);
    let count = historyMessage.length - 1
    while (count >= 0) {
      if (historyMessage[count].type === 'bot' && historyMessage[count].payload.uid > -100)
        return count
      else count--
    }
  }

 



  // const updateCustomer = (key, value) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     [key]: value
  //   }))
  // }

  const parse = async (message) => {

    // const lastIdBot = findLastMsgBot();
    // let nextId = historyMessage[lastIdBot].payload?.next;
    // let currentUid = historyMessage[lastIdBot].payload?.uid;
    // let question = historyMessage[lastIdBot].message;
  
    try {

        
    } catch (error) {
      console.error("Erreur ", error);
    } finally {
       // actions.addMessageToState(message)
      input.readOnly = true
      actions.next(message);
    }
  };
  

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
