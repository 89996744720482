import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import './result.css'
import data from './result.json'
import { sortScoreArray } from '../../utils/helper';


const Result = (props) => {
    const [best, setBest] = useState([]);
    const base_url = '/assets/images/traitment/';

    useEffect(() => {
        let scores = {
            "traitementRides": {"final": 1},
            "traitementRegulation": {"final": 1},
            "traitementSensibilite": {"final": 1},
            "traitementEclat": {"final": 0},
            "traitementHydratation": {"final": 0},
            "traitementTaches": {"final": 0},
            "traitementImperfections": {"final": 0},
            "traitementPores": {"final": 0}
        }
        let bestScores;
        if(props.needs && props.needs.length) {
            bestScores = props.needs;
        } else {
            bestScores = sortScoreArray(scores);
            const state = {myNeeds: bestScores.slice(-3), needs: bestScores}
            props.setState((prev) => {
                return { ...prev, ...state }
           })
        }

        setBest(bestScores);

        setTimeout(() => {
            props.actionProvider.next(props.payload.next)
        }, "2000");
    }, []);

    return (
        <Box>
            {
                best && best.map(item => {
                    return (
                        <div key={item.key}>
                            <h3>{item.key}</h3>
                            <div className="images-con">
                                {
                                data[item.key].map((path, index) => {
                                    const x = Math.floor(item.final*10 /2 )
                                    return <img className={x === index ? `imp` : null} 
                                            key={item.key + index} 
                                            src={`${base_url + path}`} 
                                            alt={index}  width="15%" height="15%" 
                                            style={{ marginRight: 5 }} >
                                        </img>
                                })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </Box>
    )
}

export default Result