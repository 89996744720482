import axios from 'axios'

const gydtech_url = 'https://cactus.gydtech.io/'

export const startChat = async () => {

    try {
      const result = await axios({
        method: 'post',
        url: `${gydtech_url}api/chat/hair`
      });
  
      return result.data
    } catch (error) {
      console.error(error)
    }
  
  }

export const getRoutine = async (data, subject) => {

    try {
      const result = await axios({
        method: 'post',
        url: `${gydtech_url}api/${subject}/care-routine`,
        data
      });
      return result.data
    } catch (error) {
      console.error(error)
    }
  
  }

export const getShampooAdvice = async (data) => {

    try {
      const result = await axios({
        method: 'post',
        url: `${gydtech_url}api/hair/shampoo-advice`,
        data
      });
      return result.data
    } catch (error) {
      console.error(error)
    }
  
  }

  export const getSummary = async (id) => {

    try {
      const result = await axios({
        method: 'get',
        url: `${gydtech_url}api/chat/${id}/summary`
      });
  
      return result.data
    } catch (error) {
      console.error(error)
    }
  
  }

  export const getIdForm = async (id) => {

    try {
      const result = await axios({
        method: 'get',
        url: `${gydtech_url}api/chat/${id}`
      });
  
      return result.data
    } catch (error) {
      console.error(error)
    }
  
  }