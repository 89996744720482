import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import { createClientMessage } from 'react-chatbot-kit';
import './multiChoices.css';
import { capitalizeFirstLetter, saveResWidget } from '../../utils/utils';

const MultiChoices = (props) => {
  const { setState, payload, actionProvider } = props;
  const { choices, id, sendMsg } = payload;
  const [isVisible, setIsVisible] = useState(false);
  const color_hair = ['Auburn', 'Blonds', 'Bruns', 'Chatains', 'Roux', 'Gris _ Blanc ' ]
  const [loadedImages, setLoadedImages] = useState([]);

    const handleImageLoad = (index) => {
        setLoadedImages(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2000); // 2000 millisecondes (2 secondes)

    return () => clearTimeout(timeout);
  }, []); // Le tableau vide [] garantit que cet effet ne se déclenche qu'une fois après le montage initial

  const createChatUserMessage = (msg, options = {}) => {
    const message = createClientMessage(
      msg,
      options
    );
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  };

  return (
    isVisible && (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          padding: '0 5%'
        }}
      >
        {choices.map((item, index) => (
          <Button
            className={(item?.text?.length || item?.length) > 25 ? 'btnMultiChoicesLong' : 'btnMultiChoices'}
            key={index}
            variant="outlined"
            onClick={() => {
              saveResWidget(item.text || item.value  || item, setState);
              if (sendMsg) {
                createChatUserMessage(item.text || item.value || item);
            }
              actionProvider.next(item.next || id + 1);
            }}
          >
            {item.img && <img className={color_hair.includes(item.img) ? null : 'imgMultiChoices'} src={`/assets/images/hair_icons/${item.img}.png`} width={'42'} alt={item.text || item}
             onLoad={() => handleImageLoad(index)} />}
           {(loadedImages[index] || !item.img) && ( // Afficher le texte seulement si l'image est chargée ou si l'élément n'a pas d'image
                            capitalizeFirstLetter(item.text) || capitalizeFirstLetter(item)
                        )}</Button>
        ))}
      </Box>
    )
  );
};

export default MultiChoices;
