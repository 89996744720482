import React, {useEffect, useState} from 'react'
import { Dialog, DialogContent } from '@mui/material';
import './pub.css'
import {Langage} from "../../language";
import {appLanguage} from "../../select";


const Pub = (props) => {
    const {open, image} = props;
    const [treatmentName, setTreatmentName] = useState('');
    const [num, setNum] = useState(null);

    const getText = (key) => {
        return Langage[key][appLanguage]
    }

   const treatments = [
       getText('rides'),
       getText('taches'),
       getText('imperfections'),
       getText('sensibilite'),
       getText('hydratation'),
       getText('brillance'),
       getText('eclat'),
       getText('pores'),
   ];

   const playAnim = () => {
       setNum(0);
       setTreatmentName('');
       for (let i = 0; i < 101; i++) {
           setTimeout(() => {
               setNum(i);
           }, 50 * i);
       }
       for (let i = 0; i < treatments.length; i++) {
           setTimeout(() => {
               setTreatmentName(treatments[i]);
           }, 1200 * i);
       }
   }

    useEffect(() => {
        if(open) {
            playAnim();
        }
    }, [open])
  
    return (
        <Dialog open={open}>
            <DialogContent >
                <div className={'selfy-img'}>
                    <span className={'num'}>{getText('my_analysis')}</span>
                  <div className="photo-container">
                    <img src={image} alt="pub" className={'pub'}/>
                    <img src="/assets/images/scan-animation.gif" alt="" className={'anim'}/>
                  </div>
                  <span className="in-scan">{num}%</span>
                  <button className={'treatment-name'}> {treatmentName}</button>
                </div>
                {/*<img width={300} height={600} src="/assets/images/pub.png" alt="pub"></img>*/}
            </DialogContent>
        </Dialog>
    )
}

export default Pub
