import React, { useState, useEffect } from 'react'
import { Button, Box } from '@mui/material';
import './yourDiagnostic.css'
import MyDiagnostic from '../../components/myDiagnostic/myDiagnostic';
import {Langage} from "../../language";
import {appLanguage} from "../../select";

const YourDiagnostic = (props) => {
    const { needs, arg, analysis, state } = props
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false);
      };

    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    useEffect(() => {
        if(state.needs) {
            setTimeout(() => {
                document.querySelector('#analyses-t').scrollIntoView();
            }, 1000);
        }
        // let scores = {
        //     "traitementRides": {"final": 1},
        //     "traitementRegulation": {"final": 1},
        //     "traitementSensibilite": {"final": 1},
        //     "traitementEclat": {"final": 0},
        //     "traitementHydratation": {"final": 0},
        //     "traitementTaches": {"final": 0},
        //     "traitementImperfections": {"final": 0},
        //     "traitementPores": {"final": 0}
        // }
        // let bestScores;
        // if(needs && needs.length) {
        //     bestScores = needs;
        // } else {
        //     bestScores = sortScoreArray(scores);
        //     const state = {myNeeds: bestScores.slice(-3), needs: bestScores}
        //     props.setState((prev) => {
        //         return { ...prev, ...state }
        //    })
        // }
        //document.querySelector('#my-type').innerHTML = getText([state.skinType]);
    }, [state.needs]);
 

    return (
        <Box border={1} className={'analyses'} id={'analyses-t'}>
           {/*  {
                state.analysis &&
                <Button onClick={() => setOpen(true)} variant="contained" className={'analyse-btn'}>
                    {getText('see_result')}
                </Button>
            }
            {
                state.analysis && <MyDiagnostic open={open} onClose={handleClose} analysis={state.analysis} needs={state.needs} arg={state.arg}></MyDiagnostic>
            }
            <div id={'my-type'}>

            </div> */}
            <Button onClick={ () => {props.actionProvider.next(28)} } variant="contained" className={'analyses-next'}>
                {getText('ok')}
            </Button>
        </Box>
    )
}

export default YourDiagnostic
