import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import './oneButton.css';
import { updatefollowingChat } from '../../api/service';

const OneButton = (props) => {
    const { setState, payload, actionProvider, checkedProducts } = props;
    const { url, urlAbbi, content } = payload;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);


    const postChoices = async () => {

        try {
            let id = localStorage.getItem('followingCactusId')
            const checkedProductsClean = checkedProducts.filter((element) => element !== '');
            await updatefollowingChat({ selected_products: checkedProductsClean.join(',') }, id);
        } catch (error) {
            console.error(error)
        }
    }

    const handleClick = async() => {

        await postChoices()

        if (url) {
            window.location = url;
        }

        if (urlAbbi) {
            window.open(`${urlAbbi}`, '_blank');
        }
    }

    return (
        isVisible && (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    padding: '0 5%',
                }}
            >
                <Button onClick={handleClick}>{content}</Button>
            </Box>
        )
    );
};

export default OneButton;
